.footer {
    background-color: #454636;
    padding: 0.5rem 1.25rem;
    text-align: center;
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 0.2px solid #ffffda56;
    margin-top: 5rem;
}

.footer__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4rem;
}

.footer__container h1 {
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffda;
    letter-spacing: 0.1rem;
}

.footer__container h4 {
    font-size: 1rem;
    font-weight: 400;
    text-transform: lowercase;
    color: #ffffda;
    letter-spacing: 0.1rem;
    font-family: 'ClashDisplay-Regular';
}

.footer__container h4 a {
    color: #ffffda;
    font-family: 'ClashDisplay-Regular';
}

@media (max-width: 457px) {
    .footer__container {
        flex-direction: column;
        padding: 0;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
    }

    .footer__container h1 {
        font-size: 1rem;
    }

    .footer__container h4 {
        font-size: 0.8rem;
    }

    .footer__container h4 a {
        font-size: 0.85rem;
    }
}