#dark {
  --title-color: #561217;
  --text-color: #ffffdaa6;
  --body-color: #686a52;
  --alternative-color: #7b5543b3;
  --container-color: #e5e9d2;
  --nav-color: #F38218;
  --rim-color: #2C6E63;
  --accent-color: #ffffdacc;
  --box-shadow: -4px 2px 20px 1px rgba(63, 63, 63, 0.3);
  --webkit-shadow: -4px 6px 31px 0px rgba(93, 110, 96, 0.6);
  --moz-box-shadow: -4px 6px 31px 0px rgba(93, 110, 96, 0.6);
  --insider-color: #fff2e0;
  --accent-color-darker: #244224;
  --border-color: #698269;
  --logo-color: hsl(var(--hue), var(--sat), 60%);
  --overlay-color: #eee7e1;
  --nav-overlay-color: #1b1b1b;
}


:root {
  --header-height: 3rem;
  /* =========== COLORS =========== */
  /* Color mode HSL (Hue, Saturation, lightness */
  --hue: 0;
  --sat: 0%;
  --title-color: hsl(var(--hue), var(--sat), 30%);
  --title-color-dark: hsl(var(--hue), var(--sat), 0%);
  --text-color: hsl(var(--hue), var(--sat), 46%);
  --body-color: #454636;
  --container-color: #fef4ee;
  --accent-color-darker: #244224;
  --border-color: #698269;
  --accent-color: #426342;
  --accent: var(--pink);
  --background-color: #FBE5B6;
  --color-number: #999;
  --color-link-hover: #1b1b1b;
  --color-link: #b19e7f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* =========== Font And Typography =========== */

  --body-font: "Poppins", sans-serif;

  /* 0.5rem = 8px | 1rem = 16px ...*/
  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.765rem;
  --border-radius: 1rem;
  --speed: 500ms;

  /* ========== Font Weight ========== */

  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;

  /* ========== Margins Bottom ========== */
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;
  /* ========== Z Index ========== */


  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;

  /* Responsive Typography */

}



/* ========== Base ========== */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

html {
  scroll-behavior: smooth;
  background-color: var(--background-color);
}

body {
  max-width: 100vw;
  overflow-x: hidden;
  background-color: var(--background-color);
  overscroll-behavior-x: none;
  touch-action: pan-y;

}

.no-scroll {
  overflow: hidden;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);

}


h1,
h2,
h3 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  font-family: 'ClashDisplay-Bold';
}

img {
  pointer-events: none;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;

}

img {
  max-width: 100%;
  height: auto;
}

/* ========== REUSABLE CSS CLASSES ========== */


.section {
  padding: 1.5rem 0 2rem;
}

.section__title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
}

.section__subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: 4rem;
  color: var(--accent-color);
}

.section__title,
.section__subtitle {
  text-align: center;
}

/* ========== REUSABLE CSS CLASSES ========== */

.container {
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}

.containered {
  max-width: 1268px;
  margin-left: auto;
  margin-right: auto;
}

/* .grid {
  display: grid;
  gap: 1.5rem;
}

.gridded {
  display: grid;
  gap: 1.5rem;
} */


/* ========== REUSABLE CSS CLASSES ========== */

.button {
  display: inline-block;
  background-color: var(--nav-overlay-color);
  color: var(--container-color);
  padding: 1rem 2rem;
  border-radius: 1rem;
  font-weight: var(--font-medium);
  font-family: "Satoshi-Bold", sans-serif;
  letter-spacing: 0.2rem;

}



.button__icon {
  margin-left: var(--mb-0-5);
}

.button--flex {
  display: inline-block;
  align-items: center;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}

.button--flex:hover {
  background-color: var(--title-color-dark);
  border-radius: 0rem;
}



::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.5rem;
  margin-block: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background: var(--text-color);
  border-radius: 10px;

}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}

::selection {
  background: var(--title-color);
  color: var(--container-color);
}

/* ========== BREAKPOINTS ========== */

/* For Larger Devices */



.hiddenn {
  display: none;
}


@font-face {
  font-family: 'Posterama';
  src: url('../public/fonts/Posterama.woff') format('woff'), url('../public/fonts/Posterama.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;

}


@font-face {
  font-family: 'ClashDisplay-Variable';
  src: url('../public/fonts/ClashDisplay-Variable.woff2') format('woff2'),
    url('../public/fonts/ClashDisplay-Variable.woff') format('woff'),
    url('../public/fonts/ClashDisplay-Variable.ttf') format('truetype');
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'ClashDisplay-Extralight';
  src: url('../public/fonts/ClashDisplay-Extralight.woff2') format('woff2'),
    url('../public/fonts/ClashDisplay-Extralight.woff') format('woff'),
    url('../public/fonts/ClashDisplay-Extralight.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'ClashDisplay-Light';
  src: url('../public/fonts/ClashDisplay-Light.woff2') format('woff2'),
    url('../public/fonts/ClashDisplay-Light.woff') format('woff'),
    url('../public/fonts/ClashDisplay-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'ClashDisplay-Regular';
  src: url('../public/fonts/ClashDisplay-Regular.woff2') format('woff2'),
    url('../public/fonts/ClashDisplay-Regular.woff') format('woff'),
    url('../public/fonts/ClashDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'ClashDisplay-Medium';
  src: url('../public/fonts/ClashDisplay-Medium.woff2') format('woff2'),
    url('../public/fonts/ClashDisplay-Medium.woff') format('woff'),
    url('../public/fonts/ClashDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'ClashDisplay-Bold';
  src: url('../public/fonts/ClashDisplay-Bold.woff2') format('woff2'),
    url('../public/fonts/ClashDisplay-Bold.woff') format('woff'),
    url('../public/fonts/ClashDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}