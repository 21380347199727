.overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
    overflow-y: hidden;
}

.overlay__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    place-items: center;
    background-color: #F38218;
}

.overlay__container {
    display: flex;
    position: relative;
    z-index: 9;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100vh;
    width: 100%;
    top: -3rem;
}

.overlay__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    list-style: none;
    padding: 0;
    margin: 0;
}

.overlay__list ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

.overlay__list li {
    margin-bottom: 1rem;
}


.overlay__list li a {
    font-size: 3.5rem;
    font-weight: 700;
    color: var(--title-color);
    font-family: 'ClashDisplay-Bold';
    text-transform: uppercase;
    letter-spacing: 0.4rem;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    text-align: left;
    margin-left: 2rem;
}




.hover-link {
    border: none;
    background: none;
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.hover-link>span {
    overflow: hidden;
    display: block;
    position: relative;
    pointer-events: none;
}

.hover-link>span>span {
    display: inline-block;
}

.hover-link>span>span:nth-of-type(1) {
    transition: 0.4s ease all;
}

.hover-link>span>span:nth-of-type(2) {
    transition: 0.4s ease all;
    transition-delay: 0.07s;
}

.hover-link>span>span>span {
    content: attr(data-text);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(200%);
}

.hover-link:hover>span>span,
.hover-link:focus>span>span {
    transform: translateY(-200%);
}

@media (max-width: 475px) {
    .overlay__list li a {
        font-size: 2rem;
    }

    .overlay__container {
        display: flex;
        position: relative;
        z-index: 9;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 70vh;
        width: 100%;
    }
}

.navbar-social-media ul {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    transition: all 1.5s cubic-bezier(0.16, 1, 0.3, 1);
}

.navbar-social-media ul li a {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    font-size: 1.5rem;
}

.navbar__location {
    position: absolute;
    right: -8rem;
}
.nav-footer {
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	color: var(--title-color);
}
.navbar-social-media ul li a span {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--title-color);
    font-family: 'ClashDisplay-Bold';
    text-transform: uppercase;
    letter-spacing: 0.4rem;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    text-align: left;
    margin-left: 2rem;
}

.navbar__location h2 {
    font-size: 1rem;
    font-weight: 700;
    color: var(--title-color);
    font-family: 'ClashDisplay-Light';
    text-transform: uppercase;
    letter-spacing: 0.4rem;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    text-align: left;
    margin-left: 2rem;
}