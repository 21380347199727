.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: var(--rim-color);
}

.about__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.about__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 50%;
    padding: 0 2rem;
    margin-top: 10rem;
}

.about__right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 0 2rem;
}

.right__video__background video {
    position: fixed;
    top: 0;
    right: 0;
    width: 49.8vw;
    height: 100vh;
    object-fit: cover;
    z-index: 20;
}

.about__left h1 {
    font-size: 6rem;
    margin-bottom: 1rem;
    font-family: 'ClashDisplay-Bold';
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    color: var(--nav-color);
}

.about__left__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.about__festivals {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}
.about__festivals h2 {
    font-size: 1.5rem;
    margin-top: 1rem;
    font-family: 'ClashDisplay-Bold';
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    color: var(--nav-color);
}

.about__left__container h3 {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    font-family: 'ClashDisplay-Regular';
    text-transform: uppercase;
    letter-spacing: 0.14rem;
    color: var(--nav-color);
}


@media (max-width: 467px) {
    .about__left h1 {
        font-size: 2.7rem;
    }

    .about__left__container h3 {
        font-size: 0.8rem;
    }
    .right__video__background video {
        position: fixed;
        top: 0;
        right: 0;
        width: 100.8vw;
        height: 30vh;
        object-fit: cover;
        z-index: 20;
    }
    .about__container {
        display: flex;
        flex-direction: column;    
    }
    .about__left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        padding: 0 2rem;
        margin-top: 20rem;
    }
    
}


