.nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 10vh;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    z-index: 100;
}

.nav__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    margin-right: 1rem;
}

.nav__left span{
    font-size: 2rem;
    font-weight: 700;
    color: var(--title-color);
    font-family: 'ClashDisplay-Bold';
    text-transform: uppercase;
    text-decoration: underline;
    text-decoration-color: var(--title-color);
    text-underline-offset: 0.7rem;
    cursor: pointer;
    padding-left: 0.34rem;
}

.nav__left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav__middle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 7.5rem;
}

.nav__middle h1 {
    font-size: 2rem;
    font-weight: 700;
    color: var(--title-color);
    font-family: 'ClashDisplay-Bold';
    text-transform: uppercase;
    letter-spacing: 0.4rem;
    margin-right: 2rem;
    text-align: center;
}

.nav__right {
    cursor: pointer;
}


@media (max-width: 469px) {
    .nav {
        height: 5.75rem;
    }

    .nav__container {
        max-width: 100%;
        margin-right: 0;

    }

    .nav__left span {
        font-size: 1.2rem;
        text-decoration: none;
        text-decoration-color: transparent;
        text-underline-offset: 0; 
        display: flex;
        justify-content: center;
    }

    .nav__middle h1 {
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: normal;
        padding-left: 4rem;
    }
}

.menu__dots {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    transition: all 1.52s cubic-bezier(0.16, 1, 0.3, 1);
}

.dot {
    width: 0.5rem;
    height: 0.5rem;
    background-color: var(--title-color);
    border-radius: 0.625rem;
    margin-right: 0.3rem;
    margin-bottom: 0.3rem;
    transition: all 1.52s cubic-bezier(0.16, 1, 0.3, 1);
}

.rotated {
    transform: rotate(45deg);
    /* Rotate the dots 45 degrees */
    transition: all 1.52s cubic-bezier(0.16, 1, 0.3, 1);
}

.nav-overlay {
    position: fixed;
    z-index: -1;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    transition: all 1.52s cubic-bezier(0.16, 1, 0.3, 1);
    background-color: var(--nav-overlay-color);
}

