.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: fit-content;
    overflow-y: hidden;
    position: relative;
    min-height: 100vh;
    position: relative;
    top: 6.5vh;
    @media (max-width: 768px) {
        top: 11vh;
    }

    &__container {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        &__upper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            h1 {
                font-size: 10vw;
                font-weight: 700;
                text-transform: uppercase;
                text-align: center;
                margin-bottom: 1rem;
                letter-spacing: 0.1rem;
                font-family: 'ClashDisplay-Bold';
            }
            h2 {
                font-size: 5vw;
                font-weight: 700;
                text-transform: uppercase;
                text-align: center;
                margin-bottom: 1rem;
                letter-spacing: 0.1rem;
                font-family: 'ClashDisplay-Bold';
            
            }
        }
        &_image {
            display: block;
            width: 100%;
            height: 70vh;


            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center 20%;
            }
        }
        &__lower {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 2rem;
        }

        &__events {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 2rem;
            &_upper {
                display: flex;
                width: 100%;
                gap: 1rem;
                p {

                }
            }
            &_lower {
                display: flex;
                width: 100%;
                gap: 1rem;
                align-items: center;
                ul {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    li {
                        font-family: 'ClashDisplay-Medium';
                        font-size: 2vw;
                        font-weight: 500;
                        text-transform: uppercase;
                        text-align: left;
                        margin-bottom: 1rem;
                        letter-spacing: normal;
                        color: var(--title-color);
                        border-bottom: 1px solid var(--title-color);

                        @media (max-width: 768px) {
                            font-size: 4vw;
                        }
                    }
                }
                
            }
            h3 {
                font-size: 3rem;
                font-weight: 700;
                text-transform: uppercase;
                text-align: center;
                margin-bottom: 1rem;
                letter-spacing: 0.1rem;
                font-family: 'ClashDisplay-Bold';
            }
            p {
                font-size: 3vw;
                font-weight: 500;
                text-transform: uppercase;
                text-align: left;
                margin-bottom: 1rem;
                letter-spacing: normal;
                font-family: 'ClashDisplay-Bold';
                color: var(--title-color);
                @media (max-width: 768px) {
                    font-size: 8vw;
                }
            }
        
        }
        &__videos {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 2rem;

            @media (max-width: 768px) {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 2rem 0;
            }

            &__left {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                padding: 2rem;
                @media (max-width: 768px) {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding: 0;
                }
                p {
                    font-size: 6vw;
                    font-weight: 500;
                    text-transform: uppercase;
                    text-align: left;
                    margin-bottom: 1rem;
                    letter-spacing: normal;
                    font-family: 'ClashDisplay-Bold';
                    color: var(--title-color);
                    @media (max-width: 768px) {
                        font-size: 8vw;
                    }
                }
                a {
                    font-size: 3vw;
                    font-weight: 500;
                    text-transform: uppercase;
                    text-align: left;
                    margin-bottom: 1rem;
                    letter-spacing: normal;
                    font-family: 'ClashDisplay-Medium';
                    color: var(--rim-color);
                    text-decoration: none;
                    transition: all 0.3s ease-in-out;
                    &:hover {
                        color: var(--title-color);
                    }
                }
            }

            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;

            }

        }
    }
}


/* Define the animation keyframes */
@keyframes rotateIcon {
    from {
        transform: rotate(0deg);
        transition: all 2s cubic-bezier(0.96, 1, 0.8, 0.7);

    }

    to {
        transform: rotate(360deg);
        transition: all 2s cubic-bezier(0.96, 1, 0.8, 0.7);

    }
}