.work {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding: 2rem 0;
    min-height: 100vh;
    padding-top: 8rem;
}

.video__background {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.video__background h1 {
    font-size: 3rem;
    font-weight: 700;
    color: var(--nav-color);
    font-family: 'ClashDisplay-Bold';
    text-transform: uppercase;
    letter-spacing: normal;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%
}

.video__background video {
    position: relative;
    top: 0;
    left: 0;
    width: 99vw;
    height: 70vh;
    object-fit: cover;
    z-index: 1;
    border-radius: 1rem;
}

.work__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    padding-top: 5rem;
}

.video-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 20px;

}

.video-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.video-item video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;

}

@media (max-width: 449px) {
    .video-grid {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(9, 1fr);
    }
}