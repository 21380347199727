.nav__container-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.nav__left-mobile span{
    font-size: 1rem;
    font-weight: 700;
    color: var(--title-color);
    font-family: 'ClashDisplay-Bold';
    text-transform: uppercase;
    text-decoration-color: var(--title-color);
    text-underline-offset: 0.7rem;
    cursor: pointer;
}

.nav__middle-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6.5rem;
}

.nav__middle-mobile h1 {
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: normal;
    text-transform: uppercase;
}
