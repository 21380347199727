.loader {
    background-color: var(--body-color);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;


}

.sweet-loading {
    display: flex;
    justify-content: center;
    align-items: center;

}

.sweet__loading-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
}